<template>
  <div class="chatbot" :class="{ 'open': showChat }">
    <div v-if="showChat" class="chat-container">
      <h2>문의 챗봇</h2>
      <div class="messages">
        <p v-for="message in messages" :key="message.id">{{ message.text }}</p>
      </div>
      <div v-if="currentQuestion">
  <p v-if="!currentQuestion.isHtml">{{ currentQuestion.text }}</p>
  <p v-else v-html="currentQuestion.text"></p>
  <div class="d-flex flex-column align-items-start">
    <button
      v-for="option in currentQuestion.options"
      :key="option.id"
      @click="handleAnswer(option)"
      class="btn btn-outline-primary btn-lg mb-2"
    >
      {{ option.text }}
    </button>
  </div>
</div>
    </div>
    <button @click="toggleChat" :class="['custom-btn', chatButtonClass]">{{ chatButtonText }}</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showChat: false,
      messages: [],
      chatButtonText: this.$t('chatbot.chat_button_text'),
      questions: {
        start: {
          text: this.$t('chatbot.start_question'),
          options: [
            { id: "1", text: this.$t('chatbot.option_1') },
            { id: "2", text: this.$t('chatbot.option_2') },
            { id: "3", text: this.$t('chatbot.option_3') }
          ]
        },
        "1": {
          text: this.$t('chatbot.client_questions'),
          options: [
            { id: "1-1", text: this.$t('chatbot.client_fee_question') },
            { id: "1-2", text: this.$t('chatbot.client_hiring_process') },
            { id: "1-3", text: this.$t('chatbot.client_recruitment_process') }
          ]
        },
        "1-1": {
          text: this.$t('chatbot.client_fee_answer'),
          options: [],
          isHtml: true
        },
        "1-2": {
          text: this.$t('chatbot.client_hiring_process_link'),
          options: [],
          isHtml: true
        },
        "1-3": {
          text: this.$t('chatbot.client_recruitment_process_link'),
          options: [],
          isHtml: true
        },
        "2": {
          text: this.$t('chatbot.applicant_questions'),
          options: [
            { id: "2-1", text: this.$t('chatbot.applicant_register_resume') },
            { id: "2-2", text: this.$t('chatbot.applicant_view_jobs') }
          ]
        },
        "2-1": {
          text: this.$t('chatbot.applicant_register_link'),
          options: [],
          isHtml: true
        },
        "2-2": {
          text: this.$t('chatbot.applicant_view_jobs_link'),
          options: [],
          isHtml: true
        },
        "3": {
          text: this.$t('chatbot.headhunter_questions'),
          options: [
            { id: "3-1", text: this.$t('chatbot.headhunter_first_time') },
            { id: "3-2", text: this.$t('chatbot.headhunter_contact') }
          ]
        },
        "3-1": {
          text: this.$t('chatbot.headhunter_first_time_link'),
          options: [],
          isHtml: true
        },
        "3-2": {
          text: this.$t('chatbot.headhunter_contact_info'),
          options: [],
          isHtml: true
        }
      },
      currentQuestion: null,
      chatButtonClass: "btn-14"
    };
  },
  methods: {
    toggleChat() {
      this.showChat = !this.showChat;
      this.chatButtonClass = this.showChat ? "btn-13" : "btn-14";
      this.chatButtonText = this.showChat ? this.$t('chatbot.close_button_text') : this.$t('chatbot.chat_button_text');
      if (this.showChat) {
        this.currentQuestion = this.questions.start;
      }
    },
    handleAnswer(option) {
      if (this.questions[option.id]) {
        this.currentQuestion = this.questions[option.id];
      } else {
        this.messages.push({
          id: this.messages.length,
          text: this.$t('chatbot.selected_answer_prefix') + " " + option.text

        });
      }
    },
    goToRegister() {
      window.location.href = "/register";
    },
    goToRequest() {
      window.location.href = "/headhunting-ask";
    },
    goToProcess() {
      window.location.href = "/headhunting-process";
    },
    goToSearch() {
      window.location.href = "/job-search";
    },
    goToHiring() {
      window.location.href = "/consultant-hiring";
    }
  }
};
</script>

<style>
.chatbot {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border: 1px solid #ddd;
  background-color: #fff;
  z-index: 1000;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 200px; /* 너비를 줄임 */
  max-height: 350px; /* 최대 높이 줄임 */
  display: flex;
  flex-direction: column;
}

.chat-container {
  padding: 6px; /* 패딩 줄임 */
  overflow-y: auto;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.messages {
  max-height: 120px; /* 메시지 영역 최대 높이 줄임 */
  overflow-y: auto;
  margin-bottom: 6px;
  font-size: 12px; /* 글자 크기 줄임 */
}

.messages p {
  margin: 2px 0;
  padding: 4px;
  background-color: #f0f0f0;
  border-radius: 4px;
  font-size: 12px; /* 글자 크기 줄임 */
}

button {
  margin: 2px 0;
  outline: none;
  font-size: 12px; /* 버튼 글자 크기 줄임 */
}

.custom-btn {
  width: 100%;
  padding: 6px 0; /* 버튼의 패딩 줄임 */
  border: 2px solid transparent;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  position: relative;
  font-size: 12px; /* 글자 크기 줄임 */
}

.btn-14,
.btn-13 {
  background: #ffeb3b;
  color: #000;
  z-index: 1;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.btn-14:after,
.btn-13:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  background: #e0e5ec;
  transition: all 0.3s ease;
  border-radius: 5px;
}

.btn-14:hover,
.btn-13:hover {
  color: #000;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.btn-14:hover:after,
.btn-13:hover:after {
  top: auto;
  bottom: 0;
  height: 100%;
  background: #fbc02d;
}

.btn-14:active,
.btn-13:active {
  top: 2px;
}

button.btn-outline-primary {
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-size: 15px; /* 버튼 글자 크기 줄임 */
}

button.btn-outline-primary:hover {
  background-color: #ffd700;
  color: #000;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

button.btn-outline-primary:active {
  transform: translateY(1px);
}

@media (max-width: 1000px) {
  .chatbot {
    display: none;
  }
}
</style>
