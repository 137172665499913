<template>
  <div id="app">
    <nav class="navbar">
      <div class="top-bar">
        <button class="hamburger" @click="toggleMobileMenu" v-if="isMobile">☰</button>
      </div>

      <!-- 모바일용 메뉴 컨테이너 -->
      <transition name="slide">
            <div class="mobile-menu" v-if="mobileMenuVisible">
              <ul class="mobile-nav-list">
                <img src="../assets/BS-C1-ai.svg" class="mobile-logo" alt="Company Logo"
                     @click="goMobileHome"
                     :class="{ 'active-logo': isLogoActive }"
                     @mousedown="isLogoActive = true"
                     @mouseup="isLogoActive = false"
                     @mouseleave="isLogoActive = false" />
                <!-- 모바일 메뉴 항목들을 반복 -->
                <li class="mobile-nav-item" v-for="menu in menus" :key="menu.text">
                  {{ menu.text }}
                  <!-- 모바일 하위 메뉴 -->
                  <ul class="mobile-submenu">
                    <li v-for="item in menu.items" :key="item.text">
                      <!-- @click.native로 이벤트 수신기 수정 -->
                      <router-link :to="item.path" @click.native="closeMobileMenu">{{ item.text }}</router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </transition>


      <ul class="nav-list" v-if="!isMobile">
      <img src="../assets/BS-C1-ai.svg" class="menu-logo" alt="Company Logo"

              @click="goHome"
              :class="{ 'active-logo': isLogoActive }"
              @mousedown="isLogoActive = true"
              @mouseup="isLogoActive = false"
              @mouseleave="isLogoActive = false" />
            <li class="nav-item" v-for="menu in menus" :key="menu.text" @mouseenter="hoverMenu = menu.text" @mouseleave="hoverMenu = null">
              <div class="menu-title">
                {{ menu.text }}
              </div>
              <!-- 하위 메뉴를 v-show로 조건부 렌더링 -->
              <div class="submenu" v-show="hoverMenu === menu.text">
                <!-- 하위 메뉴 항목을 반복 -->
                <div class="submenu-item" v-for="item in menu.items" :key="item.text">
      <router-link :to="item.path" class="submenu-link">
        {{ item.text }}
      </router-link>
    </div>
              </div>
            </li>

            <li class="nav-item">
              <!-- 로그인 버튼 -->
              <router-link class="nav-button" v-if="!isLoggedIn" to="/login">{{ $t('menu.login') }}</router-link>

                <!-- 회원가입 버튼 -->
                <router-link class="register-button" v-if="!isLoggedIn" to="/register">{{ $t('menu.register') }}</router-link>

                <!-- 로그아웃 버튼 -->
                <button class="nav-button" v-if="isLoggedIn" @click="handleLogout">{{ $t('menu.logout') }}</button>

                <!-- 마이페이지 버튼 -->
                <router-link class="mypage-button" v-if="isLoggedIn" to="/mypage">{{ $t('menu.mypage') }}</router-link>

            </li>
          </ul>
      <div class="gtranslate_wrapper"></div>
    </nav>
  </div>
</template>


<script>
import { mapState, mapActions } from 'vuex';


export default {
  data() {
    return {
    menus: [
   {
     text: this.$t('menu.company_intro'),
     items: [
       { text: this.$t('menu.ceo_message'), path: '/company-introduce' },
       { text: this.$t('menu.directions'), path: '/company-load' },
       { text: this.$t('menu.consultant_intro'), path: '/consultant-intro' },
       { text: this.$t('menu.consultant_hiring'), path: '/consultant-hiring' }
     ]
   },
   {
     text: this.$t('menu.job_info'),
     items: [
       { text: this.$t('menu.job_search'), path: '/job-search' }
     ]
   },
   {
     text: this.$t('menu.headhunting_request'),
     items: [
       { text: this.$t('menu.headhunting_process'), path: '/headhunting-process' },
       { text: this.$t('menu.headhunting_request'), path: '/headhunting-ask' }
     ]
   },
   {
     text: this.$t('menu.hr_resources'),
     items: [
       { text: this.$t('menu.performance_consulting'), path: '/careerconsulting' },
       { text: this.$t('menu.labor_law_cases'), path: '/work-law' }
     ]
   },
   {
     text: this.$t('menu.contact'),
     items: [
       { text: this.$t('menu.faq'), path: '/usual-ask' }
       // { text: this.$t('menu.client_talk'), path: '/client-talk' } // 필요시 추가
     ]
   }
 ],
      submenuVisible: false,
       isMobile: false,
       mobileMenuVisible: false,
       isLogoActive: false,
       hoverMenu: null // 현재 호버 중인 메뉴 저장


    };
  },

  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize(); // 컴포넌트가 생성될 때 화면 크기를 확인합니다.
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  computed: {
   ...mapState('loginStore', ['isLoggedIn']) // Vuex 상태에서 로그인 여부 가져오기
 },

  methods: {

  ...mapActions('loginStore', ['logout']), // Vuex 액션을 사용하여 로그아웃 처리


    toggleMobileMenu() {
      this.mobileMenuVisible = !this.mobileMenuVisible;

      if (this.mobileMenuVisible) {
   // 메뉴가 열리면 화면을 최상단으로 스크롤
   window.scrollTo({ top: 0, behavior: 'smooth' });
 }


    },

    closeMobileMenu() {
  this.mobileMenuVisible = false; // 메뉴를 닫도록 설정
},

    handleResize() {
      this.isMobile = window.innerWidth < 1000; // 1000px 미만이면 모바일 화면으로 간주합니다.
    },
    goHome() {
      // 현재 경로와 이동하려는 경로가 동일한지 확인
      if (this.$route.path !== '/') {
        this.$router.push({ name: 'home' });
      }
    },

    goMobileHome() {

      this.mobileMenuVisible = false; // 메뉴를 닫도록 설정
      // 현재 경로와 이동하려는 경로가 동일한지 확인
      if (this.$route.path !== '/') {
        this.$router.push({ name: 'home' });
      }
    },

    handleLogout() {
      this.logout().then(() => {
        this.goHome();
      });
    },

  },

  mounted() {


  const elements = document.querySelectorAll('.notranslate');
  elements.forEach(element => {
    if (element.innerText.includes("bansuck")) {
      element.innerText = element.innerText.replace("bansuck", "Banseog");
    }
  });



    // 스크립트 동적 추가
    const script = document.createElement('script');
    script.src = 'https://cdn.gtranslate.net/widgets/latest/float.js';
    script.defer = true;
    document.head.appendChild(script);
  }
};
</script>

<style>

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8f8f8;
  width: 100%;
  padding-top: 20px;
  border-bottom: 2px solid black;
  position: relative;
  z-index: 10;
}

.nav-item {
  position: relative;
  margin: 0 1rem;
}

.menu-title {
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  background-color: #fefefe;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.menu-title:hover {
  background-color: #f0f0f0;
}

.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  width: 250px; /* 서브메뉴의 너비를 늘립니다 */
}

.submenu-item {
  width: 100%;
  padding: 0.6rem; /* 패딩을 조금 줄여서 크기 조정 */
  text-align: left;
  font-size: 1rem; /* 글씨 크기를 조금 줄임 */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s; /* 배경색과 글씨 색상 전환에 애니메이션 추가 */
}

.submenu-item:hover {
  background-color: #302909; /* 배경색 변경 */
  color: #ffcc00; /* 글씨 색상 변경 */
}

.submenu-link {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none; /* 링크의 밑줄 제거 */
  color: inherit; /* 링크의 색상을 부모 요소에서 상속 */
}

.nav-button {
  background-color: #ffd700; /* 노란색 배경 */
  border: none;
  color: #2c3e50; /* 텍스트 색상 */
  font-weight: bold;
  cursor: pointer;
  padding: 0.5rem 1rem;
  text-decoration: none;
  border-radius: 4px; /* 둥근 모서리 */
  transition: color 0.3s, background-color 0.3s;
  margin-right: 0.5rem; /* 버튼 간격 */
  display: inline-block;
  width: 150px; /* 버튼의 동일한 너비 */
  text-align: center;
}

.nav-button:hover {
  background-color: #ffcc00; /* hover 시 좀 더 진한 노란색 */
  color: #fff; /* hover 시 텍스트 색상 변경 */
}

.register-button,
.mypage-button {
  background-color: #302909; /* 새로운 배경색 */
  color: #f5e16e; /* 글자 색상 */
  border: none;
  font-weight: bold;
  cursor: pointer;
  padding: 0.5rem 1rem;
  text-decoration: none;
  border-radius: 4px; /* 둥근 모서리 */
  transition: color 0.3s, background-color 0.3s;
  margin-right: 0.5rem; /* 버튼 간격 */
  display: inline-block;
  width: 150px; /* 버튼의 동일한 너비 */
  text-align: center;
}

.register-button:hover,
.mypage-button:hover {
  background-color: #29240a; /* hover 시 좀 더 진한 노란색 */
  color: #fff; /* hover 시 텍스트 색상 변경 */
}


.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.nav-item-button {
  display: flex;
  align-items: center;
}

.menu-logo {
  display: block;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.menu-logo.active-logo {
  opacity: 0.6;
}

.mobile-menu {
  display: none;
}

.hamburger {
  display: none;
}

.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease-out;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
}


.mobile-nav-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* 좌측 정렬 */
  margin-top: 20px; /* 상단에서 약간 내려오도록 여백 추가 */
  padding-left: 10px; /* 좌측 여백 추가 */
}

.mobile-nav-item {
  font-size: 1.1rem; /* 글씨 크기 약간 줄임 */
  margin: 0.5rem 0; /* 메뉴 항목 사이의 여백 조정 */
  cursor: pointer;
}

.mobile-menu {
  width: 100%; /* 모바일 메뉴가 화면에 맞도록 넓이 조정 */
  max-height: 80vh; /* 화면 높이에 맞게 메뉴 최대 높이 조정 */
  overflow-y: auto; /* 메뉴가 화면을 넘칠 경우 스크롤 가능하도록 */
}

.mobile-submenu {
  padding-left: 20px; /* 서브 메뉴의 좌측 여백 추가 */
  margin-top: 0.2rem; /* 서브 메뉴와 상위 메뉴 사이의 여백 줄임 */
}

.mobile-submenu li {
  font-size: 1rem; /* 서브 메뉴 글씨 크기 약간 줄임 */
  margin: 0.3rem 0; /* 서브 메뉴 항목 사이의 여백 조정 */
  text-align: left; /* 서브 메뉴 좌측 정렬 */
}
.menu-logo {
  display: block;
  cursor: pointer;
  transition: opacity 0.3s ease;
  width: 15%; /* 기존 크기의 80%로 설정 */
height: auto; /* 비율을 유지하면서 높이 자동 조정 */
}

.mobile-logo {
  display: block;
  cursor: pointer;
  transition: opacity 0.3s ease;
  width: 60%; /* 기존 크기의 80%로 설정 */
height: auto; /* 비율을 유지하면서 높이 자동 조정 */
}


@media (max-width: 1000px) {

.mobile-menu {
  overflow-y: auto;
  display: block;
  padding-top: 50px; /* 화면 상단에서 약간 내려오도록 여백 추가 */
}

.hamburger {
  display: block;
  font-size: 30px;

}

.mobile-nav-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* 좌측 정렬 */
  margin-top: 20px; /* 상단에서 약간 내려오도록 여백 추가 */
  padding-left: 10px; /* 좌측 여백 추가 */
}

.mobile-nav-item {
  font-size: 1.1rem; /* 글씨 크기 약간 줄임 */
  margin: 0.5rem 0; /* 메뉴 항목 사이의 여백 조정 */
  cursor: pointer;
}

.mobile-submenu {
  margin-top: 5px;
  padding-left: 15px; /* 서브 메뉴의 좌측 여백 추가 */
}

.mobile-submenu li {
  font-size: 1rem; /* 서브 메뉴 글씨 크기 약간 줄임 */
  margin: 0.3rem 0; /* 서브 메뉴 항목 사이의 여백 조정 */
  text-align: left; /* 서브 메뉴 좌측 정렬 */
}
}

@media (max-width: 600px) {
  .mobile-nav-list {
    font-size: 0.9rem; /* 작은 화면에서는 글씨 크기 줄임 */
  }

  .mobile-nav-item {
    font-size: 1rem; /* 작은 화면에서는 메뉴 항목 크기 줄임 */
    margin: 0.4rem 0; /* 메뉴 항목 사이의 여백 조정 */
  }

  .mobile-submenu li {
    font-size: 0.9rem; /* 작은 화면에서는 서브 메뉴 글씨 크기 줄임 */
  }
}

@media (max-width: 400px) {
  .mobile-nav-list {
    font-size: 0.8rem; /* 더 작은 화면에서는 글씨 크기 더 줄임 */
  }

  .mobile-nav-item {
    font-size: 0.9rem; /* 더 작은 화면에서는 메뉴 항목 크기 줄임 */
    margin: 0.3rem 0; /* 메뉴 항목 사이의 여백 조정 */
  }

  .mobile-submenu li {
    font-size: 0.8rem; /* 더 작은 화면에서는 서브 메뉴 글씨 크기 줄임 */
  }
}


</style>
