<template>
  <div id="app">
    <Navbar />
    <Chatbot />
    <router-view class="content-view" />
    <Footer />
  </div>
</template>

<script>
import Chatbot from './views/Chatbot.vue';
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';

export default {
  components: {
    Navbar,
    Chatbot,
    Footer
  },

  mounted() {

  window.gtranslateSettings = {
    default_language: "ko",
    native_language_names: true,
    detect_browser_language: true,
    languages: ["ko","en", "zh-CN", "ja", "de", "fr"],
    wrapper_selector: ".gtranslate_wrapper",
    switcher_horizontal_position: "right",
    switcher_vertical_position: "top",
    float_switcher_open_direction: "bottom",
    flag_style: "3d",
    alt_flags:{"en":"usa"}
  };

  <div class="gtranslate_wrapper"></div>

    // 토큰 만료 시간 계산하여 자동 로그아웃 처리
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = JSON.parse(atob(token.split('.')[1]));
      const expirationTime = decoded.exp * 1000;
      const currentTime = new Date().getTime();

      if (currentTime > expirationTime) {
        this.logout();
      } else {
        setTimeout(this.logout, expirationTime - currentTime);
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

img {
  max-width: 100%;
  height: auto;
}

/* Footer를 고정하는 스타일 */
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f8f8f8;
  padding: 10px 20px;
  text-align: center;
  border-top: 2px solid black;
  z-index: 10; /* 다른 요소들보다 위에 표시 */
}

/* router-view에 하단 여백 추가 */
.content-view {
  padding-bottom: 150px; /* Footer 높이만큼의 여백 추가 */

}

/* Navbar 컴포넌트에 대한 스타일을 보다 구체적으로 지정 */
#app > nav, #app > * > nav {
  display: flex; /* Flexbox로 설정하여 요소들을 중앙 정렬 */
  justify-content: center; /* 수평 중앙 정렬 */
  align-items: center; /* 수직 중앙 정렬 */
  padding: 20px;
  width: 100%;
  background: #f8f8f8;
  border-bottom: 2px solid black;
  position: relative;
  z-index: 10;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
