import axios from 'axios';

const state = {
  isLoggedIn: !!localStorage.getItem('token'), // 토큰이 있으면 로그인 상태로 설정
  token: localStorage.getItem('token') || '',
};


const mutations = {
  setLoginState(state, payload) {
    state.isLoggedIn = payload.isLoggedIn;
    state.token = payload.token || '';
  },
  logout(state) {
    state.isLoggedIn = false;
    state.token = '';
    localStorage.removeItem('token');
  }
};

const actions = {
  async login({ commit }, { username, password }) {
    try {
      const response = await axios.post('/api/users/login', { username, password });
      const token = response.data.token;
      localStorage.setItem('token', token);
      commit('setLoginState', { isLoggedIn: true, token });
    } catch (error) {
      console.error('로그인 오류:', error);
      throw new Error('로그인 실패. 아이디 또는 비밀번호를 확인하세요.');
    }
  },
  logout({ commit }) {
    commit('logout');
  },
  async loadUserData({ getters }) {
    try {
      const token = getters.token; // 토큰 가져오기
      const response = await axios.get('/api/signup/get-user-data', {
        headers: {
          'Authorization': token // 헤더에 토큰 추가
        }
      });
      return response.data;
    } catch (error) {
      console.error('사용자 데이터 불러오기 오류:', error);
      throw error;
    }
  },
};
const getters = {
  isLoggedIn: state => state.isLoggedIn,
  token: state => state.token
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
