<!-- src/components/Footer.vue -->
<template>
  <footer>
    <!-- 하단 내용 -->
  <p>  {{ $t('footer.contact_info') }} </br>
    {{ $t('footer.business_info') }} </p>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
/* Footer 스타일 */
footer {
  background-color: #333;
  color: white;
  text-align: left;
}

footer p {
  font-size: 12px; /* 글씨 크기 조정 */
}
</style>
