import Vue from 'vue';
import Vuex from 'vuex';

import loginStore from '@/store/modules/login.js';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const storageState = createPersistedState({
  paths: ['loginStore'], // 'loginStore'를 유지할 상태에 추가
});

export default new Vuex.Store({
  modules: {

    loginStore: loginStore, // 로그인 스토어 추가

  },
  plugins: [storageState] // Vuex 상태를 로컬스토리지에 유지
});
