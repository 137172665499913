import Vue from 'vue';
import App from './App.vue';
//import './registerServiceWorker';
import router from './router';
import store from './store';
import axios from 'axios';

// Vue i18n 라이브러리 가져오기
import VueI18n from 'vue-i18n';

// 번역 메시지 가져오기 (예: 한국어)
import kr from './locales/kr.json';

// Vue i18n 사용 설정
Vue.use(VueI18n);

// i18n 설정
const messages = {
  kr, // 한국어 메시지
  // 필요한 경우 다른 언어도 추가
};

const i18n = new VueI18n({
  locale: navigator.language.split('-')[0] || 'kr', // 브라우저 언어로 설정
  fallbackLocale: 'kr',
  messages,
});

Vue.config.productionTip = false;
Vue.prototype.$http = axios; // axios 인스턴스 설정

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import { BootstrapVue } from 'bootstrap-vue';
Vue.use(BootstrapVue);

new Vue({
  router,
  store,
  i18n, // i18n 추가
  render: h => h(App)
}).$mount('#app');

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for(let registration of registrations) {
      registration.unregister();
    }
  });
}
